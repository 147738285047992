import React from 'react'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import Logo from '../images/logo2.png'

import './styles/footer.css'

class Footer extends React.Component {

    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {

        return (

            <footer id="footer">

                <Modal isOpen={this.state.modal} fade={false} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Impressum</ModalHeader>
                    <ModalBody>
                        <p>Verantwortlich für den Inhalt der Seiten:</p>
                        <p>
                            LEHMANN+PARTNER Informatik AG
                            <br /> Pilatusstrasse 39
                            <br /> 6003 Luzern
                        </p>
                        <p>
                            <a href="tel:+41412273070">+41 41 227 3070</a>
                            <br /> <a href="mailto:info@lepa.ch">info@lepa.ch</a>
                        </p>
                    </ModalBody>
                </Modal>

                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 footer-info">
                                {/*<h3>LEHMANN+PARTNER<br />Informatik AG</h3>
                                <p>Wir machen aus Daten Informationen</p>
                            */}
                                <img src={Logo} alt="logo" width="300px" />
                            </div>
                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Nützliche Links</h4>
                                <ul>
                                    <li><FontAwesomeIcon icon={faChevronRight} /> <Link to="">Home</Link></li>
                                    <li><FontAwesomeIcon icon={faChevronRight} /> <a href="http://get.teamviewer.com/lepasupport" rel="noopener noreferrer" target="_blank">Support</a></li>
                                    <li><FontAwesomeIcon icon={faChevronRight} /> <span onClick={this.toggle}>Impressum</span></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-contact">
                                <h4>Kontakt</h4>
                                <p>LEHMANN+PARTNER Informatik AG
                                <br /> Pilatusstrasse 39
                                <br /> 6003 Luzern
                                <br /> <a href="tel:+41412273070">+41 41 227 3070</a>
                                    <br /> <a href="mailto:info@lepa.ch">info@lepa.ch</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>© 2023 – LEHMANN+PARTNER Informatik AG – Ein Unternehmen der OBT Gruppe</p>
                </div>
            </footer>
        )
    }
}

export default Footer
