import React, { Component } from "react"
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import {
    Collapse,
    Navbar,
    Nav,
    NavItem
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import CustomDropdownMenu from './dropDownMenu'

import { pages } from '../../conf/navigation'


class Navigation extends Component {

    state = {
        isOpen: false
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    collapse = () => {
        this.setState({
            isOpen: false
        });
    }

    renderNavItems = () => {
        return pages.map((page, index) => {
            if (page.links && page.links.length > 0) {
                return (
                    <CustomDropdownMenu key={"custom_" + index} links={page} index={index} collapse={this.collapse} />
                )
            }
            else {
                return (
                    <NavItem key={"item_" + index}>
                        <Link key={"link_" + index} to={page.link} onClick={this.collapse} activeClassName="active">
                            {page.name}
                        </Link>
                    </NavItem>
                )
            }
        })
    }

    render() {
        return (
            <Navbar color="black" black="true" expand="md">
                <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                    <Img fixed={this.props.logo} alt="logo" />
                </Link>
                <div className="navbar-toggler" onClick={this.toggle}>
                    <FontAwesomeIcon icon={faBars} color="white" size="lg" />
                </div>
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        {
                            this.renderNavItems()
                        }
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default Navigation
