import React from 'react'

import Navigation from './navigation'

const Header = ({ logo }) => (
  <header id="header" >
    <div className="header-container">
      <Navigation logo={logo} />
    </div>
  </header>
)

export default Header
