import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header/header'
import Footer from './footer'

import "animate.css/animate.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/layout.css'

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        },
        fileName: file(relativePath: { eq: "logo2.png" }) {
          childImageSharp {
            fixed(width: 190) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
          >
            <html lang="en" />
            <meta name="author" content="Lehmann + Partner Informatik AG" />
            <meta name="company" content="Lehmann + Partner Informatik AG" />
            <meta name="description" content="Zeugnisprozess - Machen Sie kurzen Prozess mit Ihren Zeugnissen!" />
            <meta name="keywords" content="arbeitszeugnis, zeugnis, zwischenzeugnis, erstellen, generieren, zeugnisprozess, prozess, schweiz, schweizer" />

            /* Google tag (gtag.js) */
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-4JZXL9S1LK"></script>
            <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              
              gtag('js', new Date());
              gtag('config', 'G-4JZXL9S1LK');
            `}
            </script>

          </Helmet>
          <Header logo={data.fileName.childImageSharp.fixed} />
          <div id="content">
            {children}
          </div>
          <Footer />
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
