export const pages = [
    {
        link: '/',
        name: 'Home'
    },
    {
        link: '',
        name: 'Produktinfo',
        links: [
            {
                link: '/zep-info/info',
                name: 'Übersicht'
            },
            {
                link: '/zep-info/prozess',
                name: 'Der Prozess'
            },
            {
                link: '/zep-info/applikation',
                name: 'Die Applikation'
            },
            {
                link: '/zep-info/technik',
                name: 'Die Technik'
            },
            {
                link: '/zep-info/kurzprozess',
                name: 'Der HR-Kurzprozess'
            },
        ]
    },
    {
        link: '/zep-download',
        name: 'Download'
    },
    {
        link: '/zep-referenzen',
        name: 'Referenzen'
    }

]